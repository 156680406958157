import { Button } from '@common/components/common/button';
import { DImage } from '@common/components/common/image';
import { ANDROID_STORE_URL, IOS_STORE_URL, IS_CLIENT } from '@common/constants/common';
import type { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useMemo, useRef } from 'react';

import { IMG_DOWNLOAD_GUIDE, IMG_DOWNLOAD_PAGE_LOGO } from '@/resources/images';

const formatMap = {
  living: 'living',
  food: 'foodmart'
};

const DownLoad = () => {
  const { query } = useRouter();
  const schemaRef = useRef<string>();
  const isAndroid = useRef<boolean>();
  const isDchliving = useRef<boolean>();

  const hasContinue = useMemo(() => {
    return query.type === 'web' && query.url;
  }, [query]);

  const { t } = useTranslation('common');

  /**
   * 根据domain获取相应的业态
   */
  const getFormatForHost = (host: string): string => {
    if (host.includes('foodmart')) {
      return formatMap.food;
    }
    if (host.includes('dchliving')) {
      return formatMap.living;
    }
    return formatMap.living;
  };

  const handleContinue = () => {
    if (query.type === 'web' && query.url) {
      let connector = '?';
      if (query?.url.includes('?')) connector = '&';
      const redirectUrl = `${query.url}${connector}download=${true}`;

      window.location.href = redirectUrl;
    }
  };

  function isInChatApp() {
    if (typeof navigator !== 'undefined') {
      const userAgent = navigator.userAgent.toLowerCase();

      // 检查常见的即时聊天软件
      const chatApps = [
        'micromessenger', // 微信
        'qq', // QQ
        'instagram', // Instagram
        'fbav', // Facebook App
        'fban' // Facebook App
      ];

      return chatApps.some((app) => userAgent.includes(app));
    }
    return false;
  }

  useEffect(() => {
    /** 根据链接规则重新处理schemaUrl */
    if (query.type === 'web' && query.url) {
      const { origin, host } = new URL(query.url as string);
      const nUrl = (query.url as string)
        .replace(origin, '')
        .replace(/^\/?(tc|en|cn)(\/|[^.]*)/, '/');
      const format = getFormatForHost(host);
      schemaRef.current = `dchliving://WebviewScreen?url=${encodeURIComponent(
        nUrl
      )}&format=${format}`;
    } else if (query.type === 'app' && query.url) {
      schemaRef.current = `dchliving://${query.url}`;
    }

    if (IS_CLIENT) {
      const u = navigator.userAgent;
      isAndroid.current = u.includes('Android') || u.includes('Adr');
      isDchliving.current = u.includes('dchliving');
      if (isDchliving.current) {
        if (query.type === 'web' && !!query.url) {
          window.location.href = query.url as string;
        } else {
          window.location.href = `/`;
        }
      } else if (!isInChatApp() && schemaRef.current) {
        window.location.href = schemaRef.current;
      }
    }
  }, [query.type, query.url]);

  const handleDownload = () => {
    if (isAndroid.current) {
      window.location.href = ANDROID_STORE_URL;
    } else {
      window.location.href = IOS_STORE_URL;
    }
  };

  return (
    <>
      <div className="min-h-screen bg-white">
        <div className="flex items-center flex-col">
          <DImage src={IMG_DOWNLOAD_PAGE_LOGO} className="w-55 min-h-20 h-fit mt-31" />
          <div className="px-12 mt-6 text-center text-[#666666] text-[17px] font-normal leading-6 mb-55.5">
            {t('downloadPage.tip')}
          </div>
          <div className="flex flex-col items-center ">
            {hasContinue && (
              <Button
                className="w-45  min-h-11.5  !bg-[#FFF] text-[#232323] rounded-[75px] border-[#E3E3E3] border-[1px]"
                onClick={handleContinue}
              >
                <span className="text-[16px] ">{t('downloadPage.continue')}</span>
              </Button>
            )}
            <Button
              className="w-45  min-h-11.5 mt-6 !bg-[#DC3030] text-white rounded-[75px]"
              onClick={handleDownload}
            >
              <span className="text-[16px] font-medium">{t('downloadPage.button')}</span>
            </Button>
          </div>
        </div>
      </div>

      {isInChatApp() && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-80 z-50 ">
          <div className="text-[18px] text-white font-medium text-center absolute top-13 left-1/2 -translate-x-1/2 whitespace-nowrap">
            {t('downloadGuide.Step1')}
          </div>

          <div className="w-70 text-[18px] text-white font-medium text-center absolute top-49 left-1/2 -translate-x-1/2 ">
            {t('downloadGuide.Step2')}
          </div>
          <DImage src={IMG_DOWNLOAD_GUIDE} className="w-full h-fit" />
        </div>
      )}
    </>
  );
};

export default DownLoad;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const fullUrl = `https://${ctx.req.headers.host}${ctx.req.url}`;
  const userAgent = ctx.req.headers['user-agent'] || '';
  // 检查是否为 PC
  const isPC = !/mobile/i.test(userAgent);
  const originUrl = new URL(fullUrl);
  const urlParams = originUrl?.searchParams;
  const lng = urlParams.get('lng') || 'tc';
  /** 取url download页面语言参数 */
  const i18nProps = await serverSideTranslations(lng as string, ['common']);

  if (isPC) {
    const redirectUrl = urlParams.get('url');
    const type = urlParams.get('type');

    /** type为web 跳转到url参数页面 */
    if (type === 'web' && redirectUrl) {
      return {
        redirect: {
          destination: encodeURI(redirectUrl),
          permanent: true
        }
      };
    }
    /** type为app 原生页面时 跳转到相应首页 */
    if (type === 'app' && redirectUrl) {
      return {
        redirect: {
          destination: new URL(fullUrl).origin,
          permanent: true
        }
      };
    }
  }

  return {
    props: {
      ...i18nProps
    }
  };
}
